<template>
  <v-container>
    <v-toolbar
      color="blue-grey darken-3"
      dark
      dense
    >
      <span>Customer Category</span>
      <v-spacer></v-spacer>
      <v-icon>mdi-account</v-icon>
    </v-toolbar>
    <v-data-table
      :items="custCatList"
      :headers="headers"
      dense
      :loading="this.custCatGetting"
    ></v-data-table>
  </v-container>
</template>
<script>
import custCatRepository from '@/repositories/customer-category.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'
import jwtDecode from 'jwt-decode'

const getCustCatDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
  ],
})

export default {
  name: 'CustomerCategoryTable',
  created () {
    this.getCustCat()
    this.websocketEvent()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('cust-cat'),
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
      token: state => state.auth.token,
    }),
  },
  methods: {
    getCustCat () {
      if (this.custCatGetting) return
      this.custCatGetting = true
      this.custCatGetErrors = []
      getCustCatDelay(() => {
        custCatRepository.index()
          .then(({ data }) => { this.custCatList = data })
          .catch(e => { this.custCatGetErrors = errorHandler(e) })
          .then(() => { this.custCatGetting = false })
      })
    },
    websocketEvent () {
      const userId = jwtDecode(this.token).sub
      this.echo.private('database.event')
        .listen('DBStoreEvent', this.execStoreEventPayload)
    },
    execStoreEventPayload ({ model, data }) {
      if (!model || !data) return
      if (model === 'CustomerCategory') {
        this.getCustCat()
      }
    },
  },
}
</script>
